import React from "react";
import { FinancialPlanProvider } from "./FinancialPlanContext";
import styles from "./UserInformation.module.css";
import PersonalInformation from "./sections/PersonalInformation";
import IncomeDetails from "./sections/IncomeDetails";
import ExpenseOverview from "./sections/ExpenseOverview";
import Assets from "./sections/Assets";
import Liabilities from "./sections/Liabilities";
import FinancialGoals from "./sections/FinancialGoals";
import RiskTolerance from "./sections/RiskTolerance";
import TaxSituation from "./sections/TaxSituation";
import InsuranceCoverage from "./sections/InsuranceCoverage";
import EstatePlanning from "./sections/EstatePlanning";
import RetirementPlans from "./sections/RetirementPlans";
import OtherConsiderations from "./sections/OtherConsiderations";
import MajorExpense from "./sections/MajorExpense";
import Uploads from "./sections/Uploads";

const FormPage = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here, like sending the data to a backend or saving in a database.
    alert("Form submitted!");
  };

  return (
    <FinancialPlanProvider>
      <div className={styles.container}>
        <form onSubmit={handleSubmit}>
          <PersonalInformation />
          <IncomeDetails />
          <ExpenseOverview />
          <Assets />
          <Liabilities />
          <FinancialGoals />
          <RiskTolerance />
          <TaxSituation />
          <InsuranceCoverage />
          <EstatePlanning />
          <RetirementPlans />
          <OtherConsiderations />
          <MajorExpense />
          <Uploads />

          <div className={styles.submitButton}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </FinancialPlanProvider>
  );
};

export default FormPage;
